import React from "react"
import { Container } from "@material-ui/core"
import Footer from "../components/footer/Footer"
import Nav from "../components/navigation/Nav"
import PageBanner from "../components/PageBanner"
import SEO from "../components/SEO"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp"
import Typography from "@material-ui/core/Typography"
import { styled } from "@material-ui/core/styles"

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}))

const RulesPage = ({ location }) => {
  const [expanded, setExpanded] = React.useState("panel1")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <SEO
        title="Rules"
        description="Welcome to Kumite Gaming rules for specific games."
        keywords="KIT, KITX, summer bash, game galaxy, tennessee, texas, fgc, tournament, fighing games, esports, rules"
      />
      <header>
        <Nav pathname={location.pathname} />
        <PageBanner title="KIT 2022 Game Rules" />
      </header>
      <main style={{ background: "#d4d4d4", width: "100%", padding: "60px 0" }}>
        <Container maxWidth="lg">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>General Rules</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Each Game will be run as a Double Elimination tournament
                  </Typography>
                </li>
                <li>
                  <Typography>
                    All Tournament Matches are 2/3 Games unless otherwise noted
                    under a game’s specific ruleset. Winners, Losers and Grand
                    Finals for All games are ⅗
                  </Typography>
                </li>
                <li>
                  <Typography>
                    All Macros available from the in-game controller
                    configuration menu are allowed.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Pausing the game at any time during a match will force you
                    to forfeit the round.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    You are responsible for bringing your own controller for the
                    events when competing, including adapters.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Coaching is allowed with one 30 second coaching break in a
                    set before games. Coaches must be named before their
                    matches. Coaches are not allowed on stage next to their
                    players during a match. Coaching is not allowed during an
                    ongoing match under any circumstances.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    As stated in the COVID-19 policy for KIT 2021, masks must be
                    worn during tournament play.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Collusion of any kind with your competitors is considered
                    cheating. If the Tournament Director determines that any
                    competitor is colluding to manipulate the results or
                    intentionally underperforming, the collaborating players may
                    be immediately disqualified. This determination is to be
                    made at the sole discretion of the Tournament Director.
                    Anyone disqualified in this manner forfeits all rights to
                    any titles or prizes they might have otherwise earned for
                    that tournament.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Judges and tournament organizers reserve the right to
                    overrule or make judgment calls due to unforeseen
                    circumstances. In the event a final decision needs to be
                    made, it will come from one of the head organizers of the
                    event: Ian Davis, Beely Bounedara, or Ricky McNeal.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Dead or Alive 6 (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Round settings: Normal Life 60 seconds, best 3 of 5 rounds.
                  </Typography>
                </li>
                <li>
                  <Typography>Random stage for each game.</Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Tekken 7 (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Round settings: 60 seconds, best 3 of 5 rounds.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Random stage for the first game of every set.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may either switch character or select the
                    stage. If loser elects to select the stage they must keep
                    the same character. If loser elects to change character, the
                    stage selection must be random.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Only default or preset costumes can be selected. Jack-7
                    Preset 4 and Gigas preset 3 are banned.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Street Fighter V (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>2 out of 3 rounds per game.</Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner must keep character, but may change V-Skill/V-Trigger
                    prior to the losing player selecting their
                    V-Skill/V-Trigger.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Losing player may switch character and V-Skill/V-Trigger.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Random stage select (players may opt for an agreed stage).
                  </Typography>
                </li>
                <li>
                  <Typography>
                    The Grid, Kanzuki Beach, Skies of Honor, and Flamenco Tavern
                    stages are banned from play; if these stages are randomly
                    selected repeat random stage selection process until a
                    tournament legal stage appears.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    The following costumes are banned: Pyron Gill and
                    Nergigagnte Blanka
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography>Dragonball FighterZ (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Default settings, random stage select.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same characters for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <Typography>Mortal Kombat 11 (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>
                    Tournament Mode Default Options, 90 second timer.
                  </Typography>
                </li>
                <li>
                  <Typography>2 out of 3 rounds per game.</Typography>
                </li>
                <li>
                  <Typography>Each set is best 3 out of 5 games.</Typography>
                </li>
                <li>
                  <Typography>Interactables On.</Typography>
                </li>
                <li>
                  <Typography>
                    Variations: Default Tournament Mode Variations and
                    Tournament Mode Kustom Variations.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <Typography>Guilty Gear -Strive- (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>2 out of 3 rounds per game.</Typography>
                </li>
                <li>
                  <Typography>Each set is best 3 out of 5 games.</Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              aria-controls="panel8d-content"
              id="panel8d-header"
            >
              <Typography>Mortal Kombat X (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>2 out of 3 rounds per game.</Typography>
                </li>
                <li>
                  <Typography>Each set is best 3 out of 5 games.</Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              aria-controls="panel9d-content"
              id="panel9d-header"
            >
              <Typography>Injustice 2 (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>Each set is best 3 out of 5 games.</Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              aria-controls="panel11d-content"
              id="panel11d-header"
            >
              <Typography>SoulCalibur VI (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>3 out of 5 rounds per game.</Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <AccordionSummary
              aria-controls="panel12d-content"
              id="panel12d-header"
            >
              <Typography>Samurai Showdown (PS4)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>2 out of 3 rounds per game.</Typography>
                </li>
                <li>
                  <Typography>
                    Each set is best 2 out of 3 games. Winner’s final, Loser’s
                    final and Grand final are best 3 out of 5 games.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of each game must select the same character for the
                    next game. Loser may switch.
                  </Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <AccordionSummary
              aria-controls="panel13d-content"
              id="panel13d-header"
            >
              <Typography>Smash Ultimate (Switch)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  <Typography>3 Stock, 7 minute</Typography>
                </li>
                <li>
                  <Typography>Best 3 out of 5 games for Top 32.</Typography>
                </li>
                <li>
                  <Typography>Items: Off, Hazards: Off</Typography>
                </li>
                <li>
                  <Typography>Miis: All sets legal</Typography>
                </li>
                <li>
                  <Typography>
                    Stage selection: RPS to determine bans. 1-2-1 banning format
                    for game 1.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Winner of game 1 gets 2 Bans + mDSR (cannot play on the last
                    stage you have won on).
                  </Typography>
                </li>
              </ul>
              <Typography>Stage List</Typography>
              <span>Starters</span>
              <ul>
                <li>
                  <Typography>Battlefield</Typography>
                </li>
                <li>
                  <Typography>Pokemon Stadium 2</Typography>
                </li>
                <li>
                  <Typography>Smashville</Typography>
                </li>
                <li>
                  <Typography>Town &amp; City</Typography>
                </li>
                <li>
                  <Typography>Final Destination</Typography>
                </li>
              </ul>
              <span>Counterpicks</span>
              <ul>
                <li>
                  <Typography>Yoshi's Story</Typography>
                </li>
                <li>
                  <Typography>Kalos Pokemon League</Typography>
                </li>
                <li>
                  <Typography>Small Battlefield</Typography>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </Container>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default RulesPage
